<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <div
          class="items"
          v-scroll-spy-link="{ selector: 'a', class: 'btn-primary' }"
          v-scroll-spy-active="{ selector: 'a div', class: 'btn-primary' }"
        >
          <div class="items">
            <a href="#list">
              <div class="btn w-100 btn-simple text-start"></div>
            </a>
            <a href="#add">
              <div class="btn w-100 btn-simple text-start"></div>
            </a>
          </div>
        </div>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10" v-scroll-spy="{ offset: 120 }">
      <section id="list">
        <h2>General</h2>
        <h3>Pricing</h3>
        <div class="row mt-3">
          <div class="col-4">Base currency</div>
          <div class="col-8">
            <v-select
              v-model="baseCurrency"
              :options="currencies"
              label="code"
              :reduce="(c) => c.code"
              :getOptionKey="(c) => c.code"
              :placeholder="$t(`warehouses.selectACurrency`)"
              :clearable="false"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4">Extra functions</div>
          <div class="col-8">
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16">Consignment stock</span>
                <span
                  >Allow differentiation between consignment and non-consignment
                  inventory.</span
                >
              </span>
              <input type="checkbox" v-model="priceVatType" :value="1" /><span
                class="checkmark"
              ></span>
            </label>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16">Expired date</span>
                <span
                  >Allow setting expiration dates for inventory management
                  purposes</span
                >
              </span>
              <input type="checkbox" v-model="priceVatType" :value="2" /><span
                class="checkmark"
              ></span>
            </label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4">Pricing mode</div>
          <div class="col-8">
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16"
                  >Product prices are net prices</span
                >
                <span>Add VAT to the prices at checkout</span>
              </span>
              <input type="radio" v-model="priceVatType" :value="1" /><span
                class="checkmark"
              ></span>
            </label>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16"
                  >Product prices are gross prices</span
                >
                <span>The prices already include VAT</span>
              </span>
              <input type="radio" v-model="priceVatType" :value="2" /><span
                class="checkmark"
              ></span>
            </label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4">Inventory turnover</div>
          <div class="col-8">
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16">FIFO</span>
                <span>First-in, first-out</span>
              </span>
              <input type="radio" v-model="priceVatType" :value="1" /><span
                class="checkmark"
              ></span>
            </label>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16">LIFO</span>
                <span>Last-in, first-out</span>
              </span>
              <input type="radio" v-model="priceVatType" :value="2" /><span
                class="checkmark"
              ></span>
            </label>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16">FEFO</span>
                <span>First-Expired, first-out</span>
              </span>
              <input type="radio" v-model="priceVatType" :value="3" /><span
                class="checkmark"
              ></span>
            </label>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16">HCFO</span>
                <span>Highest Cost, First Out</span>
              </span>
              <input type="radio" v-model="priceVatType" :value="4" /><span
                class="checkmark"
              ></span>
            </label>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16">LCFO</span>
                <span>Lowest Cost, First Out</span>
              </span>
              <input type="radio" v-model="priceVatType" :value="5" /><span
                class="checkmark"
              ></span>
            </label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4">Inventory turnover mode</div>
          <div class="col-8">
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16">Warehouse level</span>
                <span
                  >Sales calculated based on inventory value for
                  warehouse-received products.</span
                >
              </span>
              <input type="radio" v-model="priceVatType" :value="1" /><span
                class="checkmark"
              ></span>
            </label>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16">Company level</span>
                <span
                  >Sales calculated based on inventory value for
                  company-received products.</span
                >
              </span>
              <input type="radio" v-model="priceVatType" :value="2" /><span
                class="checkmark"
              ></span>
            </label>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16">Global level</span>
                <span
                  >calculated based on inventory value for all received
                  products.</span
                >
              </span>
              <input type="radio" v-model="priceVatType" :value="3" /><span
                class="checkmark"
              ></span>
            </label>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "General",
  data() {
    return {
      store: useStore(),
      priceVatType: 1,
      baseCurrency: "EUR",
    };
  },
  computed: {
    currencies() {
      return this.store.state.currencies;
    },
  },
  methods: {},
};
</script>
